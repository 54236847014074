import { red } from '@mui/material/colors';
import { createTheme as createMuiTheme } from '@mui/material/styles';

export function createTheme(theme) {
  return createMuiTheme({
    palette: {
      primary: {
        main: theme.primaryColor,
      },
      secondary: {
        main: theme.secondaryColor,
      },
      error: {
        main: red.A400,
      },
      // background: {
      //   default: '#fff',
      // },
      mode: theme.mode,
    },
  });
}
