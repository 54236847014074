import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import { createTheme } from "./theme/theme";
import { themeState } from "./atoms";
import { RecoilRoot, useRecoilState } from "recoil";
import GlobalStyles from "@mui/material/GlobalStyles";

function Index() {
  // eslint-disable-next-line no-unused-vars
  const [theme, setTheme] = useRecoilState(themeState);

  return (
    <ThemeProvider theme={createTheme(theme)}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <App />
    </ThemeProvider>
  );
}

ReactDOM.render(
  <RecoilRoot>
    <Index />
  </RecoilRoot>,
  document.querySelector("#root")
);
