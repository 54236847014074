import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Header from './Header'
import {Logo} from './Logo'
import {Settings} from './Settings'
import {ThemeSwap} from './ThemeSwap';
import {Colors} from '../colors/Colors'
import {MD5} from '../md5'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export function NavBar() {
  const [elevation, setElevation] = React.useState(5)
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <div onMouseEnter={() => setElevation(10)} onMouseLeave={() => setElevation(5)}>
      <Paper elevation={elevation}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Slide in={true} direction="down">
            <Stack direction="row" alignItems="center">
              <Tooltip title="*waves hi*">
                <Avatar
                  src={"https://www.gravatar.com/avatar/" + MD5("ryan.higdon@gmail.com") + '?d=identicon'}
                  sx={{ width: 48, height: 48, marginLeft: 1}}
                />
              </Tooltip>
              <Tooltip title="Welcome!">
                <Box>
                  <Logo isLargeScreen={isLargeScreen}/>
                </Box>
              </Tooltip>
            </Stack>
          </Slide>
          {!isLargeScreen ? (
            <Grid item>
              <Settings>
                <Stack alignItems="center" direction="row">
                  <Box sx={{marginLeft: 2}}>
                    <ThemeSwap />
                  </Box>
                  <Colors />
                </Stack>
              </Settings>
            </Grid>
          ) : (
            <Grid item>
              <Box sx={{marginRight: 2}}>
                <Stack alignItems="center" direction="row">
                  <ThemeSwap />
                  <Colors />
                </Stack>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
      <Divider />
    </div>
  )
}
