import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {useRecoilState} from 'recoil';
import {ColorPicker} from './ColorPicker';
import {themeState} from '../atoms';

export function Colors() {
  // eslint-disable-next-line no-unused-vars
  const [theme, setTheme] = useRecoilState(themeState);
  return (
    <>
      <Stack direction="row" spacing={1}>
        <Stack alignItems="center" justifyContent="center">
          <Box m={1}>
            <ColorPicker
              color={theme.primaryColor}
              onChange={(hexstring) => {
                setTheme({
                  ...theme,
                  primaryColor: hexstring,
                })
              }}
              tooltip="Primary Color"
            />
          </Box>
        </Stack>
        <Stack alignItems="center" justifyContent="center">
          <Box m={1}>
            <ColorPicker
              tooltip="Secondary Color"
              color={theme.secondaryColor}
              onChange={(hexstring) => {
                setTheme({
                  ...theme,
                  secondaryColor: hexstring,
                })
              }}
            />
          </Box>
        </Stack>
      </Stack>
    </>
  )
}
