import React from "react";
import Container from "@mui/material/Container";
import Blog from "./blog/Blog";
import { NavBar } from "./navbar/NavBar";
import ReactGA from "react-ga4";
import "./App.css";
import { StarWipe } from "react-wipes";

export default function App() {
  React.useEffect(() => {
    ReactGA.initialize("G-Z80S4S8E8C");
    ReactGA.send("pageview");
  }, []);
  return (
    <div style={{ height: "100%" }}>
      <StarWipe color="grey" direction="reverse">
        <NavBar />
        <Container>
          <Blog />
        </Container>
      </StarWipe>
    </div>
  );
}
