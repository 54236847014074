import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles(theme => ({
  sidebarAboutBox: {
    padding: theme.spacing(2),
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
}));

export default function AboutSection(props) {
  const classes = useStyles();
  const { archives, description, social, title } = props;
  const [isHovered, setIsHovered] = React.useState(false)
  return (
    <Grid item xs={6} md={6}>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Paper elevation={isHovered ? 2 : 1} className={classes.sidebarAboutBox}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6" color="secondary">
              {title}
            </Typography>
            <Tooltip arrow title="A few words about myself">
              <InfoIcon fontSize="sm"/>
            </Tooltip>
          </Stack>
          <Divider sx={{marginBottom:1}}/>
          <Typography>{description}</Typography>
        </Paper>
      </div>
    </Grid>
  );
}

AboutSection.propTypes = {
  archives: PropTypes.array,
  description: PropTypes.string,
  social: PropTypes.array,
  title: PropTypes.string,
};
