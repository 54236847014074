import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FlareIcon from '@mui/icons-material/Flare';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import {themeState} from '../atoms'
import {useRecoilState} from 'recoil';

export function ThemeSwap() {
  // eslint-disable-next-line no-unused-vars
  const [theme, setTheme] = useRecoilState(themeState);
  return (
    <Tooltip arrow title="Swap your theme">
      <div>
          {theme.mode === "light" ? (
            <IconButton onClick={() => {
              setTheme({
                ...theme,
                mode: 'dark',
              })
            }}>
              <DarkModeIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => {
              setTheme({
                ...theme,
                mode: 'light',
              })
            }}>
              <FlareIcon/>
            </IconButton>
          )}
      </div>
    </Tooltip>
  )
}
